exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-vacancy-js": () => import("./../../../src/pages/careers/vacancy.js" /* webpackChunkName: "component---src-pages-careers-vacancy-js" */),
  "component---src-pages-cases-index-js": () => import("./../../../src/pages/cases/index.js" /* webpackChunkName: "component---src-pages-cases-index-js" */),
  "component---src-pages-cases-internet-am-js": () => import("./../../../src/pages/cases/internet.am.js" /* webpackChunkName: "component---src-pages-cases-internet-am-js" */),
  "component---src-pages-cases-product-js": () => import("./../../../src/pages/cases/product.js" /* webpackChunkName: "component---src-pages-cases-product-js" */),
  "component---src-pages-cases-regardify-js": () => import("./../../../src/pages/cases/regardify.js" /* webpackChunkName: "component---src-pages-cases-regardify-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-ai-development-js": () => import("./../../../src/pages/services/ai-development.js" /* webpackChunkName: "component---src-pages-services-ai-development-js" */),
  "component---src-pages-services-back-end-development-js": () => import("./../../../src/pages/services/back-end-development.js" /* webpackChunkName: "component---src-pages-services-back-end-development-js" */),
  "component---src-pages-services-computer-vision-development-js": () => import("./../../../src/pages/services/computer-vision-development.js" /* webpackChunkName: "component---src-pages-services-computer-vision-development-js" */),
  "component---src-pages-services-front-end-development-js": () => import("./../../../src/pages/services/front-end-development.js" /* webpackChunkName: "component---src-pages-services-front-end-development-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mobile-development-js": () => import("./../../../src/pages/services/mobile-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-js" */),
  "component---src-pages-services-service-js": () => import("./../../../src/pages/services/service.js" /* webpackChunkName: "component---src-pages-services-service-js" */),
  "component---src-pages-services-team-augmentation-development-js": () => import("./../../../src/pages/services/team-augmentation-development.js" /* webpackChunkName: "component---src-pages-services-team-augmentation-development-js" */),
  "component---src-pages-solutions-continuous-development-js": () => import("./../../../src/pages/solutions/continuous-development.js" /* webpackChunkName: "component---src-pages-solutions-continuous-development-js" */),
  "component---src-pages-solutions-custom-development-js": () => import("./../../../src/pages/solutions/custom-development.js" /* webpackChunkName: "component---src-pages-solutions-custom-development-js" */),
  "component---src-pages-solutions-enterprise-development-js": () => import("./../../../src/pages/solutions/enterprise-development.js" /* webpackChunkName: "component---src-pages-solutions-enterprise-development-js" */),
  "component---src-pages-solutions-fintech-development-js": () => import("./../../../src/pages/solutions/fintech-development.js" /* webpackChunkName: "component---src-pages-solutions-fintech-development-js" */),
  "component---src-pages-solutions-healthcare-development-js": () => import("./../../../src/pages/solutions/healthcare-development.js" /* webpackChunkName: "component---src-pages-solutions-healthcare-development-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-solution-js": () => import("./../../../src/pages/solutions/solution.js" /* webpackChunkName: "component---src-pages-solutions-solution-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

